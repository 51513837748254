<template>
  <div class="office">
    <div class="office-main">
      <div class="bj item">
        <div class="bg-mask"></div>
        <!-- <img class="bg" :src="getUrl('bj', 'office')" alt="" /> -->
        <div class="content">
          <h3>北京办公室</h3>
          <h4>Beijing Office</h4>
        </div>
        <div class="info">
          <p class="place">
            北京市东城区隆福寺街 95 号隆福文创园1号楼6层<br />隆福寺文化区
          </p>
          <div class="subway">
            <span>5号线</span>
            <span>6号线</span>
            <span>8号线</span>
          </div>
          <p class="view">
            <span @click="goToBeijingOffice">点击在地图上查看</span>
            <img :src="arrowRight" alt="" width="24" height="24" />
          </p>
        </div>
      </div>
      <div class="sh item">
        <div class="bg-mask"></div>
        <!-- <img class="bg" :src="getUrl('sh', 'office')" alt="" /> -->
        <div class="content">
          <h3>上海办公室</h3>
          <h4>Shanghai Office</h4>
        </div>
        <div class="info">
          <p class="place">
            上海市黄浦区准海中路222号力宝广场3012<br />新天地商业区
          </p>
          <div class="subway">
            <span>1号线</span>
            <span>8号线</span>
            <span>10号线</span>
            <span>14号线</span>
          </div>
          <p class="view">
            <span @click="gotoShanghaiOffice">点击在地图上查看</span>
            <img :src="arrowRight" alt="" width="24" height="24" />
          </p>
        </div>
      </div>
      <div class="title">工作在 UBiX</div>
    </div>
  </div>
  <div class="culture-part">
    <div class="culture-part-main">
      <div class="item">
        <img :src="creative" alt="" width="56" height="56" />
        <h3>协作共赢的工作方式</h3>
        <p>砥砺创新求突破，数据驱动，实事求是</p>
        <p>以结果为导向，勇于尝试新事物，具有创造力与思考力</p>
        <p>客户为先，诚信做事，坚持学习，突破自我</p>
      </div>
      <div class="item">
        <img :src="user" alt="" width="56" height="56" />
        <h3>真诚友善的工作氛围</h3>
        <p>以人为本，工作中平等互信，畅所欲言</p>
        <p>
          以“UBiX冲突契约”为基础，鼓励公司内部同事简洁的沟通方式，不做“向上管理”
        </p>
        <p>公司提倡“信息透明”的数字化管理</p>
      </div>
      <div class="item">
        <img :src="effect" alt="" width="56" height="56" />
        <h3>友好多元的工作环境</h3>
        <p>爱公司，爱团队，爱同事，爱是团队凝聚力的土壤</p>
        <p>与行业内来自不同职业背景的优秀伙伴们一起协作，创造卓越</p>
        <p>渴望新知识，学习新技能，勇于创新的员工，都能获得职业发展机会</p>
      </div>
    </div>
  </div>
  <div class="grow-up">
    <div class="grow-up-main">
      <h3>成长在UBiX</h3>
      <h4>
        与优秀的人一起做有挑战、有意义的事，提供充分的发展空间，享受整个过程
      </h4>
      <div class="content">
        <div class="img-wrapper">
          <img :src="iconLeft" class="icon left" @click="pre" />
          <div ref="imgs" class="imgs">
            <img
              v-for="(item, index) in growUpPics"
              :key="item"
              :src="item.src"
              :class="{ active: shownIndex === index }"
            />
          </div>
          <img :src="iconLeft" class="icon right" @click="next" />
        </div>
        <div class="info">
          <div class="item">
            <h5>新人培训</h5>
            <p>
              提供定制化的新员工入职培训，给新员工一种归属感，帮助尽快适应公司环境，实现共赢
            </p>
          </div>
          <div class="item">
            <h5>文化特质</h5>
            <p>鼓励员工无限成长的工作环境，激发人才的潜质</p>
          </div>
          <div class="item">
            <h5>人才聚集</h5>
            <p>汇聚行业内的优秀人才，共同学习，相互激发</p>
          </div>
          <div class="item">
            <h5>激励和回报</h5>
            <p>设立有区分度的薪酬激励制度，让优秀的人才脱颖而出</p>
          </div>
          <div class="item">
            <h5>学习与培训</h5>
            <p>
              公司提供立体化的培训体系，通过每周培训会，企业知识库等形式，为每一位员工提供开放式的学习资源和针对性培训，不断自我提升
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="life">
    <h3>生活在UBiX</h3>
    <div class="life-part">
      <h4>办公环境</h4>
      <div class="picture-wall">
        <VScroll
          :data="officePicList"
          :steep="officeSteep"
          scroll-direction="left"
          :is-roller="false"
          style="width: 100%"
          @mouseenter="handleMouseenter"
          @mouseleave="handleMouseleave"
        >
          <div class="picture-wall">
            <div
              v-for="(item, index) in officePicList"
              :key="`${item.title}${index}`"
              class="item"
            >
              <div class="img-box">
                <img
                  :src="item.imgFullUrl"
                  alt=""
                  @click="showDialog(item.imgFullUrl)"
                />
              </div>
              <span>{{ item.title }}</span>
            </div>
          </div>
        </VScroll>
      </div>
    </div>
    <div class="life-part">
      <h4>精彩多元的员工生活</h4>
      <div class="picture-wall">
        <VScroll
          :data="lifePicList"
          :steep="lifeSteep"
          scroll-direction="left"
          :is-roller="false"
          style="width: 100%"
          @mouseenter="handleMouseenter1"
          @mouseleave="handleMouseleave1"
        >
          <div class="picture-wall">
            <div
              v-for="(item, index) in lifePicList"
              :key="`${item.title}${index}`"
              class="item"
            >
              <div class="img-box">
                <img
                  :src="item.imgFullUrl"
                  alt=""
                  @click="showDialog(item.imgFullUrl)"
                />
              </div>
              <span>{{ item.title }}</span>
            </div>
          </div>
        </VScroll>
      </div>
    </div>
  </div>
  <div class="welfare">
    <div class="welfare-main">
      <h3>务实的福利保障</h3>
      <div class="content">
        <div class="item">
          <img :src="w1" alt="" />
          <h4>提供健康美味的餐饮</h4>
          <p>丰盛的餐饮、零食和下午茶，为员工及时补充能量</p>
        </div>
        <div class="item">
          <img :src="w2" alt="" />
          <h4>完善的员工商业保险</h4>
          <p>五险一金及补充商业险，为员工提供踏实又安心的保障</p>
        </div>
        <div class="item">
          <img :src="w3" alt="" />
          <h4>地理位置优越 通勤友好</h4>
          <p>公司地理位置便捷，有效节省上下班通勤时间，通勤更友好</p>
        </div>
        <div class="item">
          <img :src="w4" alt="" />
          <h4>节日送温暖 我们与您同在</h4>
          <p>为员工准备节日福利卡，UBiX与您和您的家人们同在</p>
        </div>
        <div class="item">
          <img :src="w5" alt="" />
          <h4>多样化的健康关怀</h4>
          <p>年度员工体检，丰富的团队活动，帮助大家健康快乐地工作和生活</p>
        </div>
        <div class="item">
          <img :src="w6" alt="" />
          <h4>贴心的家庭关怀与休假</h4>
          <p>带薪年假与病假，提供员工的家属保险，多一份健康生活的保障</p>
        </div>
      </div>
    </div>
  </div>
  <div class="join-us">
    <h3>加入UBiX开启新的篇章</h3>
    <div class="btn" @click="viewJobs">查看职位</div>
  </div>
  <div v-if="dialogVisible" class="dialog">
    <div class="inner">
      <div class="inner-img-box">
        <img class="target" :src="targetImg" alt="" />
      </div>
      <img
        class="close"
        :src="close"
        width="24"
        height="24"
        @click="closeDialog"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import arrowRight from '@/assets/company/arrow-right.png'
import creative from '@/assets/company/creative.png'
import user from '@/assets/company/user.png'
import effect from '@/assets/company/effect.png'
import w1 from '@/assets/company/w1.png'
import w2 from '@/assets/company/w2.png'
import w3 from '@/assets/company/w3.png'
import w4 from '@/assets/company/w4.png'
import w5 from '@/assets/company/w5.png'
import w6 from '@/assets/company/w6.png'
import GrowUpImg1 from '@/assets/company/growUpImg1.jpg'
import GrowUpImg2 from '@/assets/company/growUpImg2.jpg'
import GrowUpImg3 from '@/assets/company/growUpImg3.jpg'
import GrowUpImg4 from '@/assets/company/growUpImg4.jpg'
import GrowUpImg5 from '@/assets/company/growUpImg5.jpg'
import iconLeft from '@/assets/company/icon-left.png'
import close from '@/assets/company/close.png'
import { useRouter } from 'vue-router'
import VScroll from '@/components/VScroll.vue'
import { onMounted, ref } from 'vue'
import { host } from '@/utils/index'
import { apiGetPicList } from '@/service/api/company'

interface PicListItem {
  title: string
  imgFullUrl: string
}

const officeSteep = ref(0)
const lifeSteep = ref(0)
const loading = ref(false)
const officePicList = ref<PicListItem[]>([])
const lifePicList = ref<PicListItem[]>([])
const router = useRouter()

const handleMouseenter = (stop: () => void) => {
  officeSteep.value = 0.4
}
const handleMouseleave = (start: () => void) => {
  officeSteep.value = 1
}

const handleMouseenter1 = (stop: () => void) => {
  lifeSteep.value = 0.4
}
const handleMouseleave1 = (start: () => void) => {
  lifeSteep.value = 1
}

const getUrl = (img: string, prefix: string, type = 'jpg') => {
  return `${host}/website/web/culture/${prefix}/${img}.${type}`
}

const growUpPics = [
  {
    key: 1,
    src: GrowUpImg1,
  },
  {
    key: 2,
    src: GrowUpImg2,
  },
  {
    key: 3,
    src: GrowUpImg3,
  },
  {
    key: 4,
    src: GrowUpImg4,
  },
  {
    key: 5,
    src: GrowUpImg5,
  },
]

const imgs = ref()
const shownIndex = ref(0)
const pre = () => {
  if (shownIndex.value <= 0) {
    shownIndex.value = growUpPics.length - 1
  } else {
    shownIndex.value--
  }
  imgs.value.style.transform = `translateX(-${553 * shownIndex.value}px)`
}
const next = () => {
  if (shownIndex.value >= growUpPics.length - 1) {
    shownIndex.value = 0
  } else {
    shownIndex.value++
  }
  imgs.value.style.transform = `translateX(-${553 * shownIndex.value}px)`
}

const officePics = [
  {
    label: '北京办公室-奖状区',
    img: '北京办公室-奖状区',
  },
  {
    label: '北京办公室-休息区',
    img: '北京办公室-休息区（前台旁）',
  },
  {
    label: '北京办公室公共区域',
    img: '北京办公室公共区域2',
  },
  {
    label: '北京办公室-会议室',
    img: '北京办公室—会议室1',
  },
  {
    label: '北京办公室前台',
    img: '北京办公室-前台',
  },
  {
    label: '前台旁休息区',
    img: '前台旁休息区',
  },
  {
    label: '上海办公室周边',
    img: '上海办公室周边',
  },
  {
    label: '上海办公室前台',
    img: '上海办公室前台',
  },
  {
    label: '上海办公室周边',
    img: '上海新天地',
  },
  {
    label: '节日装饰',
    img: '节日装饰',
  },
  {
    label: '北京窗外雪景',
    img: '北京窗外雪景',
  },
]

const lifePics = [
  {
    label: '公司年会',
    img: '公司年会1',
  },
  {
    label: '公司年会抽奖',
    img: '公司年会抽奖',
  },
  {
    label: '公司年会游戏环节',
    img: '公司年会游戏环节',
  },
  {
    label: '每月生日会',
    img: '每月生日会',
  },
  {
    label: '年会合照',
    img: '年会合照',
  },
  {
    label: '圣诞节',
    img: '圣诞节',
  },
  {
    label: '下午茶',
    img: '下午茶',
  },
  {
    label: '下午茶',
    img: '下午茶2',
  },
  {
    label: '万圣节派对',
    img: '万圣节派对',
  },
  {
    label: '圣诞节',
    img: '圣诞节2',
  },
  {
    label: '每月生日会',
    img: '每月生日会2',
  },
  {
    label: '篮球比赛',
    img: '篮球比赛',
  },
  {
    label: '羽毛球比赛',
    img: '羽毛球比赛',
  },
]
function viewJobs() {
  router.push({
    path: '/company',
    query: {
      tab: 'Jobs',
    },
  })
}

const dialogVisible = ref(false)
const targetImg = ref('')
function showDialog(src: string) {
  dialogVisible.value = true
  targetImg.value = src
}

function closeDialog() {
  dialogVisible.value = false
}

function goToBeijingOffice() {
  window.open(
    'https://map.baidu.com/poi/%E9%9A%86%E7%A6%8F%E6%96%87%E5%88%9B%E5%9B%AD/@12959993.625,4828443.09,19z?uid=af7528370e4ad8b714c8f6b0&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl',
    '_blank',
  )
}

function gotoShanghaiOffice() {
  window.open(
    'https://map.baidu.com/poi/%E5%8A%9B%E5%AE%9D%E5%B9%BF%E5%9C%BA/@13523502.006737886,3640352.4018240003,19z?uid=6113ef77121f820d8f7727e8&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl',
    '_blank',
  )
}

async function getLifeList() {
  loading.value = true
  try {
    const res = await apiGetPicList()
    if (res.code === 200) {
      officePicList.value = res.data.filter((item: any) => item.type === 1)
      lifePicList.value = res.data.filter((item: any) => item.type === 2)
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  const fn = () => {
    if (window.scrollY >= 2288) {
      lifeSteep.value = 1
      officeSteep.value = 1
      window.removeEventListener('scroll', fn)
    }
  }
  window.addEventListener('scroll', fn)
  getLifeList()
})
</script>

<style lang="scss" scoped>
@keyframes zoom {
  0% {
    bottom: 20px;
    opacity: 0;
  }

  100% {
    bottom: 60px;
    opacity: 1;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all 0.1s ease-in-out;

  .target {
    //width: 786px;
    //height: 522px;
  }

  .inner {
    position: relative;
    .inner-img-box {
      position: relative;
      width: 786px;
      height: 522px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  .close {
    position: absolute;
    top: -32px;
    right: -32px;
    cursor: pointer;
  }
}
h2 {
  width: 100%;
  height: 78px;
  font-size: $titleFont4;
  font-weight: $mediumWeight;
  color: $colorBlack1;
  line-height: 78px;
  text-align: center;
}
.office {
  width: 100%;
  background-color: $colorWhite1;
  height: 531px;
  margin-top: 2px;

  .office-main {
    // @include width-1440;
    display: flex;
    padding: 0;
    position: relative;
    height: 100%;
  }

  .bj,
  .sh {
    width: 50%;
  }
  .bj {
    background: url(@/assets/company/bj.jpg) no-repeat center;
    background-size: cover;
  }

  .sh {
    background: url(@/assets/company/sh.jpg) no-repeat center;
    background-size: cover;
  }

  .item {
    position: relative;

    .bg {
      width: 100%;
      height: 531px;
      vertical-align: bottom;
    }
    .bg-mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: all 0.8s;
    }

    &:hover {
      .bg-mask {
        opacity: 1;
      }
      .content {
        top: 188px;
        h3 {
          height: 39px;
          font-size: 28px;
          line-height: 39px;
        }
        h4 {
          font-size: $font5;
          height: 28px;
          line-height: 28px;
        }
      }

      .info {
        opacity: 1;
        animation-fill-mode: forwards;
        animation-duration: 0.8s;
        animation-name: zoom;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-duration: 0.8s;
        -webkit-animation-name: zoom;
      }
    }

    .content {
      position: absolute;
      top: 204px;
      left: 0;
      width: 100%;
      text-align: center;
      transition: all ease-in 0.3s;
      -webkit-transition: all ease-in 0.3s;
      transition-delay: 0s;

      h3 {
        height: 67px;
        font-size: 38px;
        font-weight: $mediumWeight;
        color: $colorWhite1;
        line-height: 67px;
        transition: all ease-in 0.3s;
        transition-delay: 0s;
      }

      h4 {
        height: 56px;
        font-size: 24px;
        color: $colorWhite3;
        line-height: 56px;
        transition: all ease-in 0.3s;
        transition-delay: 0s;
      }
    }

    .info {
      position: absolute;
      bottom: 60px;
      left: 0;
      opacity: 0;
      width: 100%;
      text-align: center;

      .place {
        width: 100%;
        margin: 32px 0;
        font-size: $font5;
        color: $colorWhite1;
        line-height: 36px;
      }
      .subway {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        span {
          width: 71px;
          height: 33px;
          font-size: $font4;
          color: $colorWhite1;
          line-height: 33px;
          border-radius: 2px;
          margin-right: 32px;
          background-color: rgba(217, 217, 217, 0.53);

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .view {
        display: flex;
        justify-content: center;
        cursor: pointer;

        span {
          height: 25px;
          font-size: $font4;
          color: $colorWhite1;
          line-height: 25px;
          border-bottom: 1px solid #fff;
          margin-right: 4px;
        }
      }
    }
  }

  .title {
    height: 78px;
    font-size: $titleFont4;
    font-weight: $boldWeight;
    color: $colorWhite1;
    line-height: 78px;
    position: absolute;
    top: 60px;
    left: 50%;
    width: 330px;
    text-align: center;
    margin-left: -165px;
  }
}

.culture-part {
  background-color: rgba(245, 245, 245, 1);
  width: 100%;
  padding: 60px 0;

  .culture-part-main {
    @include width-1440;
    display: flex;
    justify-content: space-between;

    .item {
      padding: 12px 20px 0;
      width: 359px;

      h3 {
        margin-top: 24px;
        margin-bottom: 16px;
        height: 36px;
        font-size: $font6;
        font-weight: $mediumWeight;
        color: $colorBlack1;
        line-height: 36px;
      }

      p {
        margin-bottom: 12px;
        font-size: $font3;
        font-weight: 400;
        color: $colorBlack2;
        line-height: 26px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.grow-up {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0;

  .grow-up-main {
    @include width-1440;
    text-align: center;

    h3 {
      height: 78px;
      font-size: $titleFont4;
      font-weight: 500;
      color: $colorBlack1;
      line-height: 78px;
      margin: 24px 0 8px;
    }
    h4 {
      font-size: $font4;
      color: $colorBlack2;
      line-height: 30px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;

      .img-wrapper {
        width: 553px;
        height: 474px;
        overflow: hidden;
        flex-shrink: 0;
        position: relative;

        .imgs {
          height: 100%;
          display: flex;
          transition: all ease-in-out 0.2s;
        }

        .icon {
          position: absolute;
          top: 237px;
          width: 24px;
          height: 24px;
          z-index: 1;
          cursor: pointer;
        }

        .left {
          left: 24px;
        }

        .right {
          right: 24px;
          transform: rotate(180deg);
        }
      }

      img {
        display: block;
        width: 553px;
        height: 474px;
        border-radius: 24px;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .info {
        margin-left: 47px;
        .item {
          margin-bottom: 24px;
          text-align: left;

          h5 {
            height: 22px;
            font-size: $font3;
            font-weight: $mediumWeight;
            color: $colorBlack1;
            line-height: 22px;
            margin-bottom: 12px;
          }
          p {
            font-size: $font3;
            color: $colorBlack2;
            line-height: 26px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.life {
  width: 100%;
  background-color: rgba(245, 245, 245, 1);
  padding: 60px 0;
  text-align: center;

  h3 {
    height: 126px;
    font-size: 56px;
    font-weight: $mediumWeight;
    color: $colorBgDark;
    line-height: 126px;
  }

  .life-part {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      height: 67px;
      font-size: $font7;
      font-weight: $mediumWeight;
      color: $colorBgDark;
      line-height: 67px;
      margin: 24px 0;
    }

    .picture-wall {
      display: flex;
      overflow: auto;

      .item {
        width: 262px;
        margin: 0 15px;
        border-radius: 12px;
        flex-basis: 262px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: left;

        span {
          display: block;
          height: 25px;
          font-size: $font4;
          color: #272727;
          line-height: 25px;
          margin-top: 16px;
          padding-left: 15px;
        }
        .img-box {
          width: 262px;
          height: 174px;
          margin: 0 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        img {
          //width: 262px;
          //height: 174px;
          border-radius: 12px;
          flex-basis: 262px;
          flex-grow: 0;
          flex-shrink: 0;
          display: block;
        }
      }
    }
  }
}

.welfare {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0;

  .welfare-main {
    @include width-1440;
  }

  h3 {
    text-align: center;
    height: 126px;
    font-size: $titleFont4;
    font-weight: $mediumWeight;
    color: $colorBgDark;
    line-height: 126px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    .item {
      padding: 12px 20px;
      margin-right: 30px;
      margin-bottom: 48px;

      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: 0;
      }

      img {
        width: 56px;
        height: 56px;
      }

      h4 {
        height: 36px;
        font-size: $font6;
        font-weight: $mediumWeight;
        color: $colorBgDark;
        line-height: 36px;
        margin: 24px 0 16px 0;
      }
      p {
        width: 318px;
        font-size: 16px;
        color: $colorBlack2;
        line-height: 26px;
      }
    }
  }
}

.join-us {
  width: 100%;
  height: 344px;
  background-color: $colorBgDark1;
  text-align: center;
  padding-top: 79px;
  box-shadow: inset 0px -1px 0px 0px rgba(217, 217, 217, 0.08);

  h3 {
    height: 90px;
    font-size: 64px;
    font-weight: $mediumWeight;
    color: $colorWhite1;
    line-height: 90px;
    margin-bottom: 40px;
  }

  .btn {
    width: 168px;
    height: 57px;
    background: $colorWhite1;
    border-radius: 4px;
    line-height: 57px;
    font-size: $font4;
    color: $colorBlack1;
    margin: 0 auto;
    cursor: pointer;
    transition: all ease-in 0.3s;

    &:hover {
      background-color: rgba(217, 217, 217, 1);
    }
  }
}
</style>
