<template>
  <section class="company">
    <div class="banner">
      <div class="banner-bg">
        <!-- <img :src="bannerBg" alt="" /> -->
      </div>
      <div class="banner-text">数据和算法驱动的端到端AI全智能营销平台</div>
      <div class="tab-wrapper">
        <el-tabs v-model="currentTab" class="tabs" @tab-click="onTabClick">
          <el-tab-pane
            v-for="item in tabs"
            :key="item.key"
            :label="item.label"
            :name="item.key"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <KeepAlive>
      <component :is="shownComp"></component>
    </KeepAlive>
  </section>
</template>

<script lang="ts" setup>
// import bannerBg from '@/assets/company/banner.png'
import About from './About.vue'
import Culture from './Culture.vue'
import Jobs from './Jobs.vue'
import News from './News.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const currentTab = ref('about')
const tabs = [
  {
    label: '关于我们',
    key: 'about',
    component: About,
  },
  {
    label: '公司文化',
    key: 'culture',
    component: Culture,
  },
  {
    label: '职业机会',
    key: 'jobs',
    component: Jobs,
  },
  {
    label: '公司动态',
    key: 'news',
    component: News,
  },
]

const shownComp = computed(() => {
  return tabs.find((item) => {
    return item.key === currentTab.value
  })?.component
})

function onTabClick(tab: any) {
  console.log('tab', tab)
  if (currentTab.value === tab.paneName) {
    return
  }
  router.push({
    path: route.path,
    query: {
      tab: tab.paneName,
    },
  })
}

watch(
  () => route.fullPath,
  () => {
    if (route.query.tab && route.query.tab !== currentTab.value) {
      currentTab.value = route.query.tab as string
    }
  },
)

onMounted(() => {
  const query = route.query
  if (query.tab) {
    currentTab.value = `${query.tab}`.toLowerCase() as string
  }
})
</script>

<style lang="scss" scoped>
.company {
  .banner {
    position: relative;
    height: 454px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);

    .banner-bg {
      height: 100%;
      width: 100%;
      background: url(@/assets/company/banner.png) no-repeat center;
      background-size: cover;
    }
    .banner-text {
      position: absolute;
      top: 137px;
      left: 0;
      width: 100%;
      text-align: center;
      height: 78px;
      line-height: 78px;
      font-size: $titleFont4;
      font-weight: 500;
      color: $colorWhite1;
    }

    .tab-wrapper {
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;

      .tabs {
        margin: 0 auto;
        width: fit-content;

        &:deep(.el-tabs__nav-wrap::after) {
          background-color: transparent;
        }
        &:deep(.el-tabs__header) {
          margin-bottom: 0;
        }
        &:deep(.el-tabs__item) {
          height: 62px;
          font-size: $titleFont1;
          font-weight: $mediumWeight;
          color: $colorBlack1;
          padding: 8px 24px 0;
          margin-right: 80px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            color: $colorBlack1;
          }
        }
        &:deep(.el-tabs__item.is-active) {
          color: $colorBgDark1;
        }

        &:deep(.el-tabs__active-bar) {
          height: 4px;
          background-color: $colorBgDark1;
        }
      }
    }
  }

  .vision {
    width: 100%;
    background-color: $colorWhite1;
    padding: 60px 0;

    .vision-main {
      @include width-1440;
    }

    h2 {
      width: 100%;
      height: 78px;
      font-size: $titleFont4;
      font-weight: 500;
      color: $colorBlack1;
      line-height: 78px;
      text-align: center;
    }
    p {
      padding: 0 226px;
      margin-top: 24px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: $colorBlack2;
      line-height: 36px;
      text-align: center;
    }
  }
}
</style>
