<template>
  <div class="news">
    <div v-if="!!newsList.length" class="news-main">
      <div
        v-for="item in newsList"
        :key="item.id"
        class="news-item"
        @click="goto(item)"
      >
        <div class="info">
          <div class="tag">{{ COMPANY_NEWS_TYPE[item.companyNewsType] }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="sub-title">{{ item.subTitle }}</div>
          <div class="created-time">{{ formatDate(item.releaseTime) }}</div>
        </div>
        <div
          class="img-box"
          :style="`background-image: url(${getImageUrl(item.imageUrl)})`"
        >
          <!-- <img
            style="border-radius: 20px"
            :src="getImageUrl(item.imageUrl)"
            alt=""
          /> -->
        </div>
        <!--  -->
        <!-- <img :src="honor" alt="" /> -->
      </div>
      <PageLoading v-if="loading" />
      <div v-else class="load-more" @click="loadMore">点击加载更多</div>
    </div>
    <div v-else class="news-main">
      <el-empty v-if="!loading" description="暂无数据" :image-size="150">
        <div class="refresh" @click="refresh">刷新</div>
      </el-empty>
      <PageLoading v-if="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { IPage } from '@/service/api/company/type'
import { apiGetNews } from '@/service/api/company'
import dayjs from 'dayjs'
import { COMPANY_NEWS_TYPE } from '@/utils/constants'
import { host } from '@/utils/index'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const page: IPage = {
  queryEntity: {
    status: 1,
  },
  pageInfo: {
    orders: [
      {
        asc: false,
        column: 'id',
      },
    ],
    current: 1,
    size: 4,
  },
}

interface News {
  id: number
  companyNewsType: string
  title: string
  subTitle: string
  imageUrl: string
  contentUrl: string
  createdOn: string
  releaseTime: string
}
const newsList = ref<News[]>([])

function formatDate(date: string) {
  if (date) {
    return dayjs(date).format('YYYY年MM月DD日')
  }
  return ''
}

function getImageUrl(imagePath: string) {
  return `${host}/${imagePath}`
}

async function getNews() {
  loading.value = true
  try {
    const res = await apiGetNews(page)
    console.log(res)
    if (res.code === 200) {
      newsList.value.push(...res.data.records)
    }
  } catch (error) {
    page.pageInfo.current--
    console.error(error)
  } finally {
    setTimeout(() => {
      loading.value = false
    }, 1000)
  }
}

const loading = ref(false)

async function loadMore() {
  loading.value = true
  page.pageInfo.current++
  await getNews()
}

async function refresh() {
  loading.value = true
  await getNews()
}

function goto(item: News) {
  router.push({ path: '/company/newsDetail', query: { id: item.id } })
  // window.open(contentUrl, '_blank')
}

onMounted(() => {
  getNews()
})
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0;

  .news-main {
    @include width-1440;
  }

  .news-item {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 18px 0px rgba(103, 123, 147, 0.12);
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 32px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .img-box {
      border-radius: 20px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 300px;
      height: 200px;
      overflow: hidden;
    }

    .info {
      width: 756px;
      // margin-right: 32px;
      .tag {
        width: 112px;
        height: 38px;
        line-height: 38px;
        background: #f3f3f5;
        border-radius: 8px;
        font-size: $font3;
        color: $colorBlack2;
        text-align: center;
      }

      .title {
        height: 45px;
        font-size: 28px;
        font-weight: $mediumWeight;
        color: $colorBlack1;
        line-height: 45px;
        margin: 24px 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .sub-title {
        height: 56px;
        font-size: $font4;
        color: $colorBlack2;
        line-height: 28px;
      }

      .created-time {
        height: 28px;
        font-size: $font4;
        color: $colorBlack3;
        line-height: 28px;
        margin-top: 8px;
      }
    }
  }

  .load-more {
    width: 100%;
    height: 57px;
    line-height: 57px;
    background: $colorBgLight;
    border-radius: 43px;
    font-size: $font4;
    color: $colorBlack3;
    text-align: center;
    margin-top: 60px;
    cursor: pointer;
  }

  .refresh {
    width: 100px;
    color: $colorBlack2;
    margin-bottom: 30px;
    margin-top: 20px;
    height: 32px;
    line-height: 32px;
    background: $colorBgLight;
    border-radius: 20px;
    font-size: $font2;
    text-align: center;
    cursor: pointer;
  }
}
</style>
