<template>
  <div class="solution">
    <div class="solution-text">
      <div class="wp">
        <h3 class="title">我们的解决方案</h3>
        <h3 class="sub-title">
          提供定制化的AI智能营销解决方案，满足广告主和开发者的需求
        </h3>
        <ul class="solution-list">
          <li
            v-for="item in sloutionList"
            :key="item.id"
            class="solution-item"
            @click="onJumpTo(item.link)"
          >
            <img :src="item.src" class="item-iamge" />
            <div class="item-box">
              <h4 class="item-title">{{ item.title }}</h4>
              <p class="item-desc">{{ item.desc }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import userIcon from '@/assets/home/userIcon.png'
import marketIcon from '@/assets/home/marketIcon.png'
import flowIcon from '@/assets/home/flowIcon.png'
import adxIcon from '@/assets/home/adxIcon.png'
import { useRouter } from 'vue-router'

const router = useRouter()
const sloutionList = [
  {
    id: 1,
    title: '用户增长',
    desc: '帮助客户实现持续的用户增长与商业增长',
    src: userIcon,
    link: '/product/dsp',
  },
  {
    id: 2,
    title: '用户再营销',
    desc: '用户全生命周期价值转化和流失召回服务',
    src: marketIcon,
    link: '/product/dsp2',
  },
  {
    id: 3,
    title: '广告交易',
    desc: 'ADX广告交易，链接上下游',
    src: adxIcon,
    link: '/product/adx',
  },
  {
    id: 4,
    title: '流量变现',
    desc: 'SAAS级别的聚合SDK商业化解决方案',
    src: flowIcon,
    link: '/solution',
  },
]

const onJumpTo = (url: string) => {
  router.push(url)
}
</script>

<style lang="scss" scoped>
.solution {
  position: relative;
  overflow: hidden;
  background-color: $colorWhite1;
  .wp {
    @include width-1440;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      @include title-black;
      margin-bottom: 8px;
      margin-top: 84px;
      text-align: center;
    }
    .sub-title {
      @include sub-title;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 48px;
    }
    .solution-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      .solution-item {
        width: 262px;
        height: 402px;
        background: $colorBgLight;
        position: relative;
        border-radius: $border12;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          .item-iamge {
            filter: unset;
          }
        }
        .item-iamge {
          width: 100%;
          filter: saturate(30%); /* 饱和度为50% */
        }
        .item-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 30px 24px;
          .item-title {
            color: $colorBlack1;
            line-height: 34px;
            font-size: $titleFont1;
            font-weight: $mediumWeight;
            padding-bottom: 18px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          }
          .item-desc {
            margin-top: 12px;
            line-height: 19px;
            font-size: $font3;
            color: $colorBlack3;
          }
        }
      }
    }
  }
}
</style>
