<template>
  <div id="product" class="product">
    <div
      class="product-bg"
      :style="{
        width: `${1292 * (scrollTop / animationHeight + 1)}px`,
        borderRadius: `${borderNum}px`,
      }"
    >
      <img
        v-if="isMobile()"
        :src="productVideoImg"
        style="width: 100%; margin: 0 auto"
      />
      <video
        v-else
        id="myVideo"
        loop
        autoplay
        muted
        :style="{ borderRadius: `${borderNum}px` }"
      >
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <div class="product-main">
      <div class="wp">
        <h4 class="title">我们的产品</h4>
        <h4 class="sub-title">数据和算法驱动的，端到端的智能营销产品体系</h4>
        <div class="content">
          <div class="item-list">
            <div class="small-wp">
              <div class="small-item item" @click="onJumpTo('dsp')">
                <div class="detail">
                  <p class="name">UBiX Performance</p>
                  <p class="name">DSP Platform</p>
                  <p class="desc">天玑效果营销投放平台</p>
                </div>
              </div>
              <div class="small-item item">
                <div class="detail">
                  <p class="name">UBiX Brand</p>
                  <p class="name">Guaranteed Delivery Platform</p>
                  <p class="desc">天枢品牌营销投放平台</p>
                </div>
              </div>
            </div>
            <div class="normal-item item" @click="onJumpTo('adx')">
              <div class="detail">
                <p class="name">UBiADX Platform</p>
                <p class="desc">UBiADX 广告交易平台</p>
              </div>
            </div>
            <div class="normal-item item" @click="onJumpTo('md')">
              <div class="detail">
                <p class="name">Mediation N.O.W. Platform</p>
                <p class="desc">即刻聚合平台</p>
              </div>
            </div>
          </div>
          <div class="long-item item" @click="onJumpTo('bd')">
            <div class="detail">
              <p class="name">UBiX</p>
              <p class="name">Big Data Platform</p>
              <p class="desc">天权大数据中台</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-connect">
      <p class="connect">
        准备好加入新的AI全智能营销时代了吗？
        <span class="link" @click="onJumpToConnect">
          联系我们<img :src="rightIcon" />
        </span>
      </p>
      <p class="connect">定制属于您的商业方案</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount, onMounted, computed } from 'vue'
import rightIcon from '@/assets/home/rightIcon.png'
import productVideoImg from '@/assets/home/productVideoImg.png'
// import video from '@/assets/home/video.mp4'
import { useRouter, useRoute } from 'vue-router'
import { host } from '@/utils/index'
import { getSource, isMobile } from '@/utils/index'

const videoSrc = `${host}/website/web/assets/home/homeVideoBg.mp4`

const router = useRouter()
const route = useRoute()

window.onload = function () {
  const video = document.getElementById('myVideo') as HTMLVideoElement //获取，函数执行完成后local内存释放
  if (video) {
    video.play()
    if (video.paused) {
      //判断是否处于暂停状态
      video.play() //开启播放
    }
  }
}

const onJumpTo = (to: string) => {
  router.push(`/product/${to}`)
}

const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}

const scrollTop = ref(0)
// 判断刷新、进入页面的时候 是否有滚动条 如果没有滚动条则进入的时候会触发初始动画 如果已经有了滚动条则不触发初始动画
// 滚动过的页面 也不在触发初始动画
// const hasScrollTop = ref(false)
const animationHeight = ref(620)

const scrollFun = () => {
  scrollTop.value = window.scrollY
}

const handleScroll = () => {
  window.addEventListener('scroll', scrollFun)
}

onMounted(() => {
  handleScroll()
})

const borderNum = computed(() => {
  const value = 24 * (1 - scrollTop.value / 100)
  return value < 0 ? 0 : 24 * (1 - scrollTop.value / 100)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollFun)
})
</script>

<style lang="scss" scoped>
$colorPorducItemBg: rgba(245, 245, 245, 0.9);
.product {
  position: relative;
  background-color: #fff;
  .product-bg {
    font-size: 0;
    height: 812px;
    margin: 0 auto;
    min-width: 1292px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: $border24;
    background: $colorBgBlack;
    //background: no-repeat center center/cover;
    //background: grey;
    //background: url(@/assets/home/video.mp4) center no-repeat;
    video {
      // margin-top: -2px;
      width: 100%;
      //object-fit: cover;
    }
  }
  .product-main {
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%;
    .wp {
      @include width-1440;
      padding-top: 64px;
      padding-bottom: 40px;
      .title {
        @include title-white;
        margin-bottom: 8px;
        text-align: center;
      }
      .sub-title {
        @include sub-title;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $colorWhite2;
        margin-bottom: 48px;
      }
      .content {
        .item-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .small-wp {
            height: 348px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
        .item {
          width: 358px;
          display: flex;
          align-items: center;
          background-color: $colorPorducItemBg;
          cursor: pointer;
          border-radius: $border12;
          .detail {
            .name {
              font-size: $font6;
              line-height: 26px;
              color: $colorBlack2;
              margin-bottom: 4px;
            }
            .desc {
              font-size: 26px;
              height: 36px;
              line-height: 36px;
              color: $colorBlack1;
              font-weight: $mediumWeight;
              margin-top: 8px;
            }
          }
        }
        .small-item {
          height: 162px;
          padding: 24px;
        }
        .normal-item {
          height: 348px;
          padding: 24px;
        }
        .long-item {
          margin-top: 24px;
          width: 100%;
          height: 162px;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .product-connect {
    background: $colorBgWhite;
    padding: 40px 0 32px;
    font-size: $font4;
    color: $colorBlack2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .connect {
      height: 25px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .link {
        margin-left: 8px;
        color: $colorActive;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
