<template>
  <header class="header">
    <div class="header-main">
      <a href="/" class="logo-box">
        <img class="logo" :src="logo" alt="" />
      </a>
      <div class="nav">
        <ul class="link-nav">
          <li
            v-for="(item, index) in linkNavList"
            :key="item.key"
            class="link-nav-item"
            @mouseover="hoverNav(item, index)"
          >
            <span
              class="title"
              :class="{
                isActive:
                  item.path === activeKey || activeKey.indexOf(item.path) > -1,
              }"
              >{{ item.value }}</span
            >
          </li>
        </ul>
        <div
          v-if="
            selectedMenu &&
            selectedMenu.dropMenuList &&
            selectedMenu.dropMenuList.length > 0
          "
          class="dropdown-content"
        >
          <div class="dropdown-content-main">
            <!-- <div class="dropdown-title">
              <h3>{{ selectedMenu.value }}</h3>
              <div class="block"></div>
            </div> -->
            <div class="dropdown-menu">
              <p
                v-for="dropMenu in selectedMenu.dropMenuList"
                :key="dropMenu.key"
                class="dropdown-content-item"
                :class="{ isActiveMenu: dropMenu.path === activeKey }"
                :style="{ paddingLeft: getPaddingLeft() }"
                @click.prevent.stop="onDropMenuClick(dropMenu)"
              >
                {{ dropMenu.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="login-nav">
        <span class="login-nav-item" @click="onConnect">注册</span>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import logo from '@/assets/common/logo.png'
import { useRoute, useRouter } from 'vue-router'
import { getSource } from '@/utils/index'

interface IMenuItem {
  key: string
  value: string
  path: string
  dropMenuList?: IMenuItem[] | []
}

const getPaddingLeft = () => {
  const objList = document.getElementsByClassName('link-nav-item')
  const obj = objList[hoverMenuIdx.value]
  // 10是菜单上左右padding
  const left = obj.getBoundingClientRect()?.left + 10
  return `${left}px`
}
const router = useRouter()
const route = useRoute()
const linkNavList = [
  {
    key: '1',
    value: '产品',
    path: '/product',
    dropMenuList: [
      {
        key: '13',
        value: '天玑效果营销投放平台',
        path: '/product/dsp',
        dropMenuList: [],
      },
      {
        key: '12',
        value: 'UBiADX 广告交易平台',
        path: '/product/adx',
        dropMenuList: [],
      },
      {
        key: '11',
        value: '即刻聚合平台',
        path: '/product/md',
        dropMenuList: [],
      },
      {
        key: '15',
        value: '天权大数据中台',
        path: '/product/bd',
        dropMenuList: [],
      },
    ],
  },
  {
    key: '2',
    value: '解决方案',
    path: '/solution',
    dropMenuList: [
      {
        key: '21',
        value: '用户增长',
        path: '/product/dsp',
      },
      {
        key: '22',
        value: '用户再营销',
        path: '/product/dsp2',
      },
      {
        key: '23',
        value: '广告交易',
        path: '/product/adx',
      },
      {
        key: '24',
        value: '流量变现',
        path: '/solution',
      },
    ],
  },
  {
    key: '3',
    value: '公司',
    path: '/company',
    dropMenuList: [
      {
        key: '31',
        value: '关于我们',
        path: '/company?tab=about',
      },
      {
        key: '32',
        value: '公司文化',
        path: '/company?tab=culture',
      },
      {
        key: '33',
        value: '职业机会',
        path: '/company?tab=jobs',
      },
      {
        key: '34',
        value: '公司动态',
        path: '/company?tab=news',
      },
    ],
  },
]

const hoverMenuIdx = ref(0)
const selectedMenu = ref<IMenuItem | undefined>(undefined)
const hoverNav = (item: IMenuItem, index: number) => {
  selectedMenu.value = item
  hoverMenuIdx.value = index
}

const activeKey = ref('')

const onConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}
watch(
  () => route.path,
  (newVal) => {
    activeKey.value = newVal
  },
  { deep: false, immediate: true },
)

const onDropMenuClick = (item: {
  key: string
  value: string
  path: string
}) => {
  router.push(item.path)
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  z-index: 1;
  height: 68px;
  background-color: $colorBgDark1;

  .header-main {
    @include width-1440;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-box {
      position: relative;
      .logo {
        height: 68px;
      }
    }

    .nav {
      display: flex;
      align-items: center;
      color: $colorWhite2;

      &:hover {
        .dropdown-content {
          display: block;
        }
      }

      .dropdown-content {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 68px;
        background-color: rgba(43, 43, 43, 1);
        display: none;

        .dropdown-content-main {
          display: flex;
          width: 1440px;
        }

        .dropdown-title {
          display: flex;
          width: 430px;

          h3 {
            padding: 22px 16px 0 0;
            text-align: right;
            flex: 1;
            color: $colorWhite1;
            font-size: $font3;
          }
          .block {
            width: 1px;
            background-color: rgba(56, 56, 56, 1);
            margin-left: 5px;
            margin-right: 4px;
          }
        }

        .dropdown-menu {
          padding: 18px 0 18px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .dropdown-content-item {
            height: 30px;
            line-height: 30px;
            margin-bottom: 4px;
            font-size: $font3;
            cursor: pointer;
            color: $colorWhite2;
            transition: color ease-in 0.3s;

            &:hover {
              color: $colorWhite1;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .link-nav {
        display: flex;
        font-size: $font3;

        .link-nav-item {
          padding: 0 10px;
          margin-right: 60px;
          height: 68px;
          line-height: 68px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            color: $colorWhite1;
          }
          .title {
            display: block;
            cursor: pointer;

            &.isActive {
              color: $colorWhite1;
            }
            &:hover {
              color: $colorWhite1;
            }
          }
        }
      }
    }
    .login-nav {
      display: flex;
      align-items: center;
      font-size: $font3;
      .login-nav-item {
        color: $colorWhite2;
        cursor: pointer;
        &:hover {
          color: $colorWhite1;
        }
      }
    }
  }
}
</style>
