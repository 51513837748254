<template>
  <section class="dsp">
    <div class="banner">
      <div class="banner-bg">
        <img :src="bannerBg" alt="" />
      </div>
      <div class="wp">
        <p class="tip">天玑效果营销投放平台</p>
        <p class="title">天玑投放引擎</p>
        <p class="sub-title top">
          以SDK开发者流量为基础，数据为导向，依赖科学的算法，叠加创意内容服
        </p>
        <p class="sub-title">
          务，为客户提供从用户获取、留存、价值转化到流失召回全生命周期的增长
        </p>
        <p class="sub-title">服务，帮助客户实现持续的用户增长和商业增长</p>
        <span class="more" @click="onJumpToConnect">联系我们</span>
      </div>
    </div>

    <div class="advantage">
      <p class="name">机器学习驱动</p>
      <p class="desc">先进的算法能力为业务赋能</p>
      <div class="box-wrapper">
        <div class="box-item">
          <img :src="forecast" />
          <div class="title">精准预测</div>
          <div>机器学习模型使用深度神经网络结</div>
          <div>合多方数据训练，精准预测</div>
        </div>
        <div class="box-item middle">
          <img :src="innovate" />
          <div class="title">迭代创新</div>
          <div>持续更新、进化的模型，及时适应</div>
          <div>效果变化，稳定投放成本</div>
        </div>
        <div class="box-item">
          <img :src="target" />
          <div class="title">目标丰富</div>
          <div>支持浅层，深层的多样考核目标，</div>
          <div>全方位实现投放计划</div>
        </div>
      </div>
    </div>

    <div class="data-driven">
      <div class="left">
        <div class="desc">
          <p class="desc-title">数据驱动</p>
          <p class="desc-title">高效达成投放目标</p>
        </div>
        <div class="explain">
          <p>DMP系统积累了多方数据，清洗和脱敏后，为客户定向投放和模型</p>
          <p>提供支持。AI 技术驱动的深度智能算法，支持多种投放目标优化，</p>
          <p>高效触达高质量用户，稳定投放成本</p>
        </div>
      </div>
      <div class="right">
        <img :src="data" />
      </div>
    </div>

    <div class="flow-feature">
      <div class="title special-title">深挖流量点位特征 提升转化</div>
      <img :src="flowPoint" />
    </div>

    <div class="ai-system">
      <div class="title special-title">AI智投系统功能</div>
      <div class="tab-wrapper">
        <el-tabs v-model="currentTab" class="tabs">
          <el-tab-pane
            v-for="item in tabsList"
            :key="item.key"
            :label="item.label"
            :name="item.key"
          ></el-tab-pane>
        </el-tabs>
        <div class="tabs-icon tabs-icon1">
          <img :src="rightArrow" alt="" />
        </div>
        <div class="tabs-icon tabs-icon2">
          <img :src="rightArrow" alt="" />
        </div>
        <div class="tabs-bottom-line"></div>
      </div>
      <div v-if="currentTab === 'AIGC'" class="tabs-content">
        <div class="left">
          <div class="title">AIGC引领广告创意变革</div>
          <div class="desc desc-top">
            AIGC的引入重塑了创意生产流程，结合先进的AIGC模
          </div>
          <div class="desc">
            型，创意素材制作更加智能化、自动化，全面提升创意
          </div>
          <div class="desc">生成效率和效果，引领广告创意与广告投放的革新</div>
        </div>
        <div class="right">
          <img v-if="isMobile()" :src="dspVideoImg" />
          <video
            v-else
            id="myVideo"
            loop
            muted
            style="border-radius: 24px"
            :poster="dspAIGCVideoBg"
          >
            <source :src="videoSrc" type="video/mp4" />
          </video>
        </div>
      </div>
      <div v-if="currentTab === 'FLOW'" class="tabs-content">
        <div class="left">
          <div class="title">智选流量</div>
          <div class="desc desc-top">简单配置 筛选精准流量</div>
        </div>
        <div class="right">
          <img :src="flow" alt="" />
        </div>
      </div>
      <div v-if="currentTab === 'DPA'" class="tabs-content">
        <div class="left">
          <div class="title">DPA动态商品库</div>
          <div class="desc desc-top">海量商品高效管理，个性化精准投放</div>
        </div>
        <div class="right">
          <img :src="dpaImg" alt="" />
        </div>
      </div>
    </div>

    <div class="media-source">
      <div class="title">海量优质媒体资源</div>
      <div class="desc">
        依托长期合作SDK开发者，全场景、多方位覆盖各类用户群体
      </div>
      <MediaResource />
    </div>

    <div class="cooperate-case">
      <div class="title special-title">合作案例</div>
      <ul class="demo-list">
        <li
          v-for="(item, index) in demoList"
          :key="item.key"
          class="list-item"
          :class="`list-item-demo${index} list-item-demo-common`"
          @mouseenter="onMouseEnter(index)"
          @mouseleave="onMouseLeave(index)"
        >
          <div class="item-content-bg">
            <div class="item-content">
              <div
                class="item-text-wrapper"
                style="display: flex; flex-direction: column"
              >
                <p class="item-title">
                  {{ item.title }}
                </p>
                <ul class="sub-item-list">
                  <li
                    v-for="subItem in item.numList"
                    :key="subItem.key"
                    class="sub-item"
                  >
                    <span v-if="subItem.tip" class="sub-item-tip">
                      {{ subItem.tip }}
                    </span>
                    <p class="sub-item-title sub-item-title-right">
                      <span>{{ subItem.value }}</span>
                      <img
                        v-if="subItem.showUpIcon"
                        :src="greenUpIcon"
                        class="green-up-icon"
                      />
                      <img
                        v-if="subItem.showDownIcon"
                        :src="greenUpIcon"
                        class="green-up-icon green-down-icon"
                      />
                      <span
                        v-if="!subItem.showUpIcon && !subItem.showDownIcon"
                        style="
                          font-size: 24px;
                          font-weight: 900;
                          margin-left: 3px;
                        "
                        >+</span
                      >
                    </p>
                  </li>
                </ul>
              </div>
              <p class="item-desc">
                {{ item.desc }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Cooperation title="与您的用户建立全新的链接" />
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import forecast from '@/assets/dsp/icon-forecast.png'
import innovate from '@/assets/dsp/icon-innovate.png'
import target from '@/assets/dsp/icon-target.png'
import data from '@/assets/dsp/data.jpg'
import flowPoint from '@/assets/dsp/flowPoint.jpg'
import dpaImg from '@/assets/dsp/ai-dpa.png'
import flow from '@/assets/dsp/ai-flow.png'
import dspVideoImg from '@/assets/dsp/dspVideoImg.png'
import bannerBg from '@/assets/dsp/dsp-bannerBg.png'
import rightArrow from '@/assets/dsp/right-arrow.png'
import greenUpIcon from '@/assets/dsp/greenUpIcon.png'
import dspAIGCVideoBg from '@/assets/dsp/dspAIGCVideoBg.jpg'
import MediaResource from '@/components/MediaResource.vue'
import Cooperation from '@/components/Cooperation.vue'
import { host, getSource, isMobile } from '@/utils/index'

const videoSrc = `${host}/website/web/assets/dsp/dspAIGCVideo.mp4`

window.onload = function () {
  const video = document.getElementById('myVideo') as HTMLVideoElement //获取，函数执行完成后local内存释放
  if (video) {
    video.play()
    if (video.paused) {
      //判断是否处于暂停状态
      video.play() //开启播放
    }
  }
}

const tabsList = [
  {
    key: 'AIGC',
    label: 'AI创意',
  },
  {
    key: 'FLOW',
    label: '智选流量',
  },
  {
    key: 'DPA',
    label: 'DPA',
  },
]

const demoList = [
  {
    key: '1',
    // image: eCommerce,
    title: '电商行业',
    desc: '某电商客户大促期间，通过UBiX流量&优化策略双重加持，量级提升3倍，转化效果超目标',
    numList: [
      {
        key: '1',
        value: '3x',
        tip: '量级提升',
        showUpIcon: true,
        showDownIcon: false,
      },
    ],
  },
  {
    key: '2',
    // image: localLife,
    title: '本地生活',
    desc: '某出行类客户，通过UBiX流量，单日激活量大幅提升，远超预期，激活成本降低30%',
    numList: [
      {
        key: '1',
        value: '30%',
        tip: '激活成本',
        showUpIcon: false,
        showDownIcon: true,
      },
    ],
  },
  {
    key: '3',
    // image: gameIndustry,
    title: '游戏行业',
    desc: '某武侠类游戏客户，通过UBiX平台优化策略，首日ROI目标达成，月度ROI达到300%',
    numList: [
      {
        key: '1',
        value: '300%',
        tip: '月度ROI达到',
        showUpIcon: false,
        showDownIcon: false,
      },
    ],
  },
  {
    key: '4',
    // image: socialIndustry,
    title: '社交行业',
    desc: '某内容平台，通过UBiX定制化的推广策略，整体考核成本降低20%以上，ROI提升40%',
    numList: [
      {
        key: '1',
        value: '40%',
        tip: 'ROI提升',
        showUpIcon: true,
        showDownIcon: false,
      },
    ],
  },
]

const currentTab = ref('AIGC')

const onMouseEnter = (index: number) => {
  for (let i = 0; i < demoList.length; i++) {
    const demoObj = document.getElementsByClassName(
      `list-item-demo${i}`,
    )[0] as HTMLElement
    if (index === i) {
      demoObj.style.width = '329px'
      const contentTitleObj = demoObj.getElementsByClassName(
        `item-text-wrapper`,
      )[0] as HTMLElement
      contentTitleObj.style.display = 'flex'
      contentTitleObj.style.flexDirection = 'row'
      contentTitleObj.style.alignItems = 'center'
      contentTitleObj.style.justifyContent = 'space-between'

      const subItemObj = demoObj.getElementsByClassName(
        `sub-item`,
      )[0] as HTMLElement
      subItemObj.style.marginTop = '0px'
      subItemObj.style.marginRight = '0px'
    } else {
      demoObj.style.width = '241px'
      const contentListObj = demoObj.getElementsByClassName(
        `sub-item-list`,
      )[0] as HTMLElement
      contentListObj.style.height = '0'
      // contentListObj.style.display = 'none'
      const contentDescObj = demoObj.getElementsByClassName(
        `item-desc`,
      )[0] as HTMLElement
      contentDescObj.style.display = 'none'
      const contentTitleObj = demoObj.getElementsByClassName(
        `item-text-wrapper`,
      )[0] as HTMLElement
      contentTitleObj.style.display = 'flex'
      contentTitleObj.style.flexDirection = 'column'
      contentTitleObj.style.alignItems = 'start'
      contentTitleObj.style.justifyContent = 'flex-end'
    }
  }
}

const onMouseLeave = (index: number) => {
  for (let i = 0; i < demoList.length; i++) {
    const demoObj = document.getElementsByClassName(
      `list-item-demo${i}`,
    )[0] as HTMLElement
    demoObj.style.width = '261px'
    const contentListObj = demoObj.getElementsByClassName(
      `sub-item-list`,
    )[0] as HTMLElement
    contentListObj.style.height = '78px'
    // contentListObj.style.display = 'flex'
    const contentDescObj = demoObj.getElementsByClassName(
      `item-desc`,
    )[0] as HTMLElement
    contentDescObj.style.display = 'block'
    const contentTitleObj = demoObj.getElementsByClassName(
      `item-text-wrapper`,
    )[0] as HTMLElement
    contentTitleObj.style.display = 'flex'
    contentTitleObj.style.flexDirection = 'column'
    contentTitleObj.style.alignItems = 'start'
    contentTitleObj.style.justifyContent = 'flex-end'
  }
}

const router = useRouter()
const route = useRoute()
const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}
</script>

<style lang="scss" scoped>
.title {
  height: 78px;
  line-height: 66px;
  font-size: 56px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}

.btn {
  width: 168px;
  height: 57px;
  margin: 56px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;
  font-weight: 400;
  font-size: 18px;
}

.dsp {
  width: 100%;
  background: #ffffff;

  .banner {
    position: relative;
    height: 641px;
    display: flex;
    justify-content: center;
    .banner-bg {
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .wp {
      @include width-1440;
      position: absolute;
      top: 120px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        height: 34px;
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 34px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
        line-height: 66px;
      }
      .sub-title {
        @include sub-title;
        height: 40px;
        &.top {
          margin-top: 32px;
        }
      }
      .more {
        margin-top: 56px;
        @include start-btn;
      }
    }
  }

  .advantage {
    text-align: center;
    padding: 84px 0 60px;
    .name {
      height: 40px;
      line-height: 40px;
      font-size: 24px;
      font-weight: 400;
      color: #2a64c5;
    }
    .desc {
      margin: 8px 0 48px;
      height: 78px;
      font-size: 56px;
      font-weight: 500;
      color: #000000;
    }
    .box-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.65);
      line-height: 26px;
      font-weight: 400;
      font-size: 16px;
      .box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 358px;
        height: 208px;
        img {
          width: 56px;
          height: 56px;
        }
        .title {
          height: 36px;
          line-height: 36px;
          font-size: 22px;
          font-weight: 500;
          color: #000000;
          margin: 24px 0 16px 0;
        }
      }
      .middle {
        margin: 0 30px;
      }
    }
  }

  .data-driven {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    .left {
      padding: 24px 32px;
      margin-top: 23px;
      .desc-title {
        line-height: 94px;
        font-size: 56px;
        font-weight: 500;
        color: #000000;
      }

      .explain {
        margin-top: 16px;
      }

      .explain > p {
        height: 34px;
        line-height: 34px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .right {
      height: 400px;
      margin-left: 62px;
      img {
        height: 100%;
      }
    }
  }
  .flow-feature {
    padding: 84px 0 60px;
    text-align: center;
    .special-title {
      margin-bottom: 48px;
    }
    img {
      width: 1136px;
      height: 560px;
    }
  }

  .ai-system {
    @include width-1440;
    .special-title {
      margin: 84px 0 48px;
    }

    .tab-wrapper {
      margin-bottom: 56px;
      position: relative;

      .tabs {
        margin: 0 auto;
        width: fit-content;

        &:deep(.el-tabs__nav-wrap::after) {
          background-color: #fff;
        }

        &:deep(.el-tabs__header) {
          margin-bottom: 0;
        }
        &:deep(.el-tabs__item) {
          height: 62px;
          font-size: 24px;
          font-weight: 500;
          color: #000;
          padding: 8px 24px 0;
          margin: 0 94px;

          :last-child {
            margin-right: 0;
          }
        }

        &:deep(.el-tabs__active-bar) {
          height: 4px;
          background-color: #272727;
        }
      }

      .tabs-icon {
        position: absolute;
        width: 28px;
        height: 28px;
        top: 17px;
      }
      .tabs-icon1 {
        left: 399px;
      }
      .tabs-icon2 {
        left: 731px;
      }
      .tabs-bottom-line {
        position: absolute;
        top: 60px;
        width: 100%;
        height: 2px;
        background: rgba(0, 0, 0, 0.12);
        z-index: 2;
      }
    }

    .tabs {
      position: relative;
      display: flex;
      justify-content: center;

      .tabs-name {
        line-height: 28px;
        padding: 8px 24px 20px;
        font-size: 24px;
        font-weight: 500;
        color: #000000;
      }

      .select-item {
        position: absolute;
        top: 56px;
        width: 96px;
        height: 4px;
        background: #272727;
      }
    }
    .line {
      width: 100%;
      background: #000;
      box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .tabs-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        padding: 24px 32px;
        text-align: left;
        .title {
          text-align: left;
          height: 54px;
          line-height: 54px;
          font-size: 32px;
          font-weight: 500;
          color: #000000;
        }
        .desc {
          line-height: 34px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
        .desc-top {
          margin-top: 12px;
        }
      }
      .right {
        width: 553px;
        height: 335px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .media-source {
    margin-top: 144px;
    .desc {
      margin: 8px 0 48px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .cooperate-case {
    @include width-1440;
    padding: 84px 152px 60px;
    .special-title {
      margin-bottom: 48px;
    }

    .demo-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .list-item-demo0 {
        background: url(@/assets/dsp/e-commerce.png) no-repeat center;
        background-size: cover;
        &:hover {
          width: 329px !important;
          .item-content-bg {
            .item-content {
              .item-desc {
                height: auto;
              }
            }
          }
        }
      }
      .list-item-demo1 {
        background: url(@/assets/dsp/local-life.png) no-repeat center;
        background-size: cover;
        &:hover {
          width: 329px !important;
          .item-content-bg {
            .item-content {
              .item-desc {
                height: auto;
              }
            }
          }
        }
      }
      .list-item-demo2 {
        background: url(@/assets/dsp/game-industry.png) no-repeat center;
        background-size: cover;
        &:hover {
          width: 329px !important;
          .item-content-bg {
            .item-content {
              .item-desc {
                height: auto;
              }
            }
          }
        }
      }
      .list-item-demo3 {
        background: url(@/assets/dsp/social-industry.png) no-repeat center;
        background-size: cover;
        &:hover {
          width: 329px !important;
          .item-content-bg {
            .item-content {
              .item-desc {
                height: auto;
              }
            }
          }
        }
      }
      .list-item {
        width: 262px;
        height: 414px;
        display: flex;
        justify-content: space-between;
        border-radius: $border24;
        opacity: 1;
        position: relative;
        transition: all 0.4s;
        cursor: pointer;
        .item-content-bg {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .item-content {
            width: 100%;
            padding: 16px 24px;
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: flex-end;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
            border-radius: $border24;
            .item-title {
              font-size: 32px;
              font-weight: $mediumWeight;
              color: $colorWhite1;
              line-height: 45px;
              overflow: hidden;
              overflow: hidden;
              min-width: 140px;
              max-width: 140px;
            }
            .sub-item-list {
              display: flex;
              margin-top: 4px;
              overflow: hidden;
              .sub-item {
                margin-right: 40px;
                .sub-item-tip {
                  height: 22px;
                  font-size: $font3;
                  color: $colorWhite2;
                }
                .sub-item-title {
                  font-size: $titleFont2;
                  color: $colorGreen3;
                  height: 50px;
                  line-height: 50px;
                  font-weight: $mediumWeight;
                  .green-up-icon {
                    margin-left: 5px;
                    width: 14px;
                    height: 12px;
                  }
                  .green-down-icon {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
            .item-desc {
              width: 281px;
              height: 0;
              font-size: $font3;
              color: $colorWhite2;
              line-height: 26px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
