<template>
  <div class="cooperation">
    <div class="cooperation-text">
      <div class="wp">
        <h3 class="title">{{ title }}</h3>
        <span class="more" @click="onJumpToConnect">联系我们</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import { getSource } from '@/utils/index'

const router = useRouter()
const route = useRoute()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
})

const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}
</script>

<style lang="scss" scoped>
.cooperation {
  position: relative;
  height: 343px;
  background-color: $colorBgDark;
  box-shadow: inset 0px -1px 0px 0px rgba(217, 217, 217, 0.08);
  .wp {
    @include width-1440;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      @include title-white;
      margin-top: 78px;
      margin-bottom: 52px;
      text-align: center;
    }
    .more {
      @include start-btn;
      color: $colorBlack1;
      background-color: $colorBgWhite;
      &:hover {
        background: $colorHoverBg;
      }
    }
  }
}
</style>
